import Galleryimg from "../components/Index/Galleryimg";
import { Fragment } from "react";
import { RiGalleryFill } from "react-icons/ri";
import IMG1 from "../assets/NA/ol-36x18-1.jpg";
import IMG2 from "../assets/NA/ac-36x18-1.jpg";
import IMG3 from "../assets/NA/ac-72x36-1.jpg";
import IMG4 from "../assets/NA/ac-72x36-2.jpg";
import IMG5 from "../assets/NA/ac-60x36-1.jpg";
import IMG6 from "../assets/NA/ac-48x30-1.jpeg";
import IMG7 from "../assets/NA/ac-30x30-1.jpeg";
import IMG8 from "../assets/NA/ac-30x30-2.jpeg";
import IMG9 from "../assets/NA/ac-24x24-1.jpeg";
import IMG10 from "../assets/NA/ac-48x30-2.jpeg";
import IMG11 from "../assets/NA/ac-36x24-1.jpg";
import IMG12 from "../assets/NA/ac-36x24-2.jpg";
import IMG13 from "../assets/NA/ac-34x28-1.jpg";
import IMG14 from "../assets/NA/ac-30x24-1.jpg";
import IMG15 from "../assets/NA/ac-30x24-2.jpg";

const NewArrival = () => {
  const ImgData = [
    {
      img: IMG1,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `36" x 18"inch`,
      sold: " ",
    },
    {
      img: IMG2,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `36" x 18"inch`,
      sold: " ",
    },
    {
      img: IMG3,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `72" x 36"inch`,
      sold: " ",
    },
    {
      img: IMG4,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `72" x 36"inch`,
      sold: " ",
    },
    {
      img: IMG5,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "SOLD",
    },
    {
      img: IMG6,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `48" x 30"inch`,
      sold: " ",
    },
    {
      img: IMG7,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `30" x 30"inch`,
      sold: " ",
    },

    {
      img: IMG8,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `30" x 30"inch`,
      sold: " ",
    },
    {
      img: IMG9,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG10,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `48" x 30"inch`,
      sold: "SOLD",
    },
    {
      img: IMG11,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `36" x 24"inch`,
      sold: " ",
    },
    {
      img: IMG12,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `36" x 24"inch`,
      sold: " ",
    },
    {
      img: IMG13,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `34" x 28"inch`,
      sold: " ",
    },
    {
      img: IMG14,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `30" x 24"inch`,
      sold: " ",
    },
    {
      img: IMG15,
      title: "Acrylic on Canvas painting",
      painting: "Acrylic on Canvas",
      size: `30" x 24"inch`,
      sold: " ",
    },
 ];
  return (
    <Fragment>
      <div className="py-8 bg-gradient-to-r from-red-900 to-amber-800 pt-36 md:pt-24">
        <div className="container mx-auto md:px-28">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline">
            <span className="mt-1 mr-3 text-gray-300">
              <RiGalleryFill />
            </span>{" "}
            New Arrival
          </h2>
        </div>
      </div>

      <Galleryimg passData={ImgData} />

      <div className="bg-zinc-900">
        <div className="container mx-auto md:px-28 pt-5 pb-12">
          <div className="w-full flex items-center">
          <iframe className="px-4 rounded-md w-full min-h-min h-96" src="https://www.youtube.com/embed/jUcJlG0jM0k" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        
          </div>
       </div>
      </div>
    </Fragment>
  );
};

export default NewArrival;
