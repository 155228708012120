import Galleryimg from "../components/Index/Galleryimg";
import { Fragment } from "react";
import { RiGalleryFill } from "react-icons/ri";
import IMG1 from "../assets/gallery/ac-60x36-1.jpg";
import IMG2 from "../assets/gallery/ac-60x36-2.jpg";
import IMG3 from "../assets/gallery/ac-60x36-3.jpg";
import IMG4 from "../assets/gallery/ac-48x36-2.jpg";
import IMG5 from "../assets/gallery/ac-48x48-1.jpg";
import IMG6 from "../assets/gallery/ac-48x36-1.jpg";
import IMG7 from "../assets/gallery/ac-48x48-2.jpg";
import IMG8 from "../assets/gallery/ac-24x18-1.jpg";
import IMG9 from "../assets/gallery/ac-48x36-3.jpg";
import IMG10 from "../assets/gallery/ac-24x24-1.jpg";
import IMG11 from "../assets/gallery/ac-24x24-2.jpg";
import IMG12 from "../assets/gallery/ac-48x48-3.jpg";
import IMG13 from "../assets/gallery/ac-48x36-1.jpeg";
import IMG14 from "../assets/gallery/ac-60x36-1.jpeg";
import IMG15 from "../assets/gallery/ac-72x36-1.jpeg";
import IMG16 from "../assets/gallery/ac-24x24-3.jpeg";
import IMG17 from "../assets/gallery/ac-48x24-1.jpeg";
import IMG18 from "../assets/gallery/ac-24x24-4.jpeg";
import IMG19 from "../assets/gallery/ac-60x36-2.jpeg";
import IMG20 from "../assets/gallery/ac-36x24-1.jpeg";
import IMG21 from "../assets/gallery/ac-48x36-6.jpeg";
import IMG22 from "../assets/gallery/ac-48x36-7.jpeg";

import IMGWC1 from "../assets/gallery/wc-11x20-1.jpg";
import IMGWC2 from "../assets/gallery/wc-11x20-2.jpg";
import IMGWC3 from "../assets/gallery/wc-14x20-1.jpg";
import IMGWC4 from "../assets/gallery/wc-22x30-1.jpg";
import IMGWC5 from "../assets/gallery/wc-15x20-1.jpg";
import IMGWC6 from "../assets/gallery/wc-15x20-2.jpg";
import IMGWC7 from "../assets/gallery/wc-15x20-3.jpg";
import IMGWC8 from "../assets/gallery/wc-15x20-4.jpg";
import IMGWC9 from "../assets/gallery/wc-15x20-5.jpg";
import IMGWC10 from "../assets/gallery/wc-15x20-6.jpg";
import IMGWC11 from "../assets/gallery/wc-15x20-7.jpg";
import IMGWC12 from "../assets/gallery/wc-15x20-8.jpg";
import IMGWC13 from "../assets/gallery/wc-20x30-1.jpg";
import IMGWC14 from "../assets/gallery/wc-22x30-2.jpg";

import IMGWC15 from "../assets/gallery/wc-22x30-3.jpg";

import IMGWC16 from "../assets/gallery/wc-22x30-4.jpg";

import IMGWC17 from "../assets/gallery/wc-20x14-1.jpg";

import IMGWC18 from "../assets/gallery/wc-22x30-5.jpg";

import IMGWC19 from "../assets/gallery/wc-20x14-2.jpg";

import IMGWC20 from "../assets/gallery/wc-22x30-6.jpg";
import IMGWC21 from "../assets/gallery/wc-15x20-9.jpeg";
import IMGWC22 from "../assets/gallery/wc-14x20-2.jpeg";
import IMGWC23 from "../assets/gallery/wc-14x11-1.jpeg";
import IMGWC24 from "../assets/gallery/wc-20x14-3.jpeg";
import IMGWC25 from "../assets/gallery/wc-20x14-4.jpeg";
import IMGWC26 from "../assets/gallery/wc-20x14-5.jpeg";
import IMGWC27 from "../assets/gallery/wc-20x14-6.jpeg";
import IMGWC28 from "../assets/gallery/wc-20x14-7.jpeg";
import IMGWC29 from "../assets/gallery/wc-11x18-1.jpeg";

const Gallery = () => {
  const ImgData = [
    {
      img: IMG1,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "SOLD",
    },

    {
      img: IMG2,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "SOLD",
    },
    {
      img: IMG3,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "",
    },
    {
      img: IMG4,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "SOLD",
    },

    {
      img: IMG5,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 48"inch`,
      sold: "",
    },

    {
      img: IMG6,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "SOLD",
    },
    {
      img: IMG7,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 48"inch`,
      sold: "",
    },

    {
      img: IMG8,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 18"inch`,
      sold: "SOLD",
    },
    {
      img: IMG9,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "SOLD",
    },
    {
      img: IMG10,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG11,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG12,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 48"inch`,
      sold: "SOLD",
    },
    {
      img: IMG13,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "",
    },
    {
      img: IMG14,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `60" x 36"inch`,
      sold: "",
    },
    {
      img: IMG15,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `72" x 36"inch`,
      sold: "",
    },
    {
      img: IMG16,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG17,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG18,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG19,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG20,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `24" x 24"inch`,
      sold: "SOLD",
    },
    {
      img: IMG21,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "SOLD",
    },
    {
      img: IMG22,
      title: "image title 2",
      painting: "Acrylic on Canvas",
      size: `48" x 36"inch`,
      sold: "SOLD",
    },
   
   
   
   
  ];

// water color

  const Watercolor = [
    {
      img: IMGWC1,
      title: "image title 2",
      painting: "Watercolor",
      size: `11" x 20"inch`,
      sold: "",
    },

    {
      img: IMGWC2,
      title: "image title 2",
      painting: "Watercolor",
      size: `11" x 20"inch`,
      sold: "SOLD",
    },
    {
      img: IMGWC3,
      title: "image title 2",
      painting: "Watercolor",
      size: `14" x 20"inch`,
      sold: "SOLD",
    },
    {
      img: IMGWC4,
      title: "image title 2",
      painting: "Watercolor",
      size: `22" x 30"inch`,
      sold: "",
    },
    {
      img: IMGWC5,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC6,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC7,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC8,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC9,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC10,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC11,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC12,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC13,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 30"inch`,
      sold: "",
    },
    {
      img: IMGWC14,
      title: "image title 2",
      painting: "Watercolor",
      size: `22" x 30"inch`,
      sold: "",
    },

    {
      img: IMGWC15,
      title: "image title 2",
      painting: "Watercolor",
      size: `30" x 22"inch`,
      sold: "",
    },

    {
      img: IMGWC16,
      title: "image title 2",
      painting: "Watercolor",
      size: `22" x 30"inch`,
      sold: "SOLD",
    },
    {
      img: IMGWC17,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC18,
      title: "image title 2",
      painting: "Watercolor",
      size: `22" x 30"inch`,
      sold: "",
    },
    {
      img: IMGWC19,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC20,
      title: "image title 2",
      painting: "Watercolor",
      size: `22" x 30"inch`,
      sold: "",
    },
    {
      img: IMGWC21,
      title: "image title 2",
      painting: "Watercolor",
      size: `15" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC22,
      title: "image title 2",
      painting: "Watercolor",
      size: `14" x 20"inch`,
      sold: "",
    },
    {
      img: IMGWC23,
      title: "image title 2",
      painting: "Watercolor",
      size: `14" x 11"inch`,
      sold: "",
    },
    {
      img: IMGWC24,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC25,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC26,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC27,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC28,
      title: "image title 2",
      painting: "Watercolor",
      size: `20" x 14"inch`,
      sold: "",
    },
    {
      img: IMGWC29,
      title: "image title 2",
      painting: "Watercolor",
      size: `11" x 18"inch`,
      sold: "",
    },

  ];
  return (
    <Fragment>
      <div className="py-8 bg-gradient-to-r from-red-900 to-amber-800 pt-36 md:pt-24">
        <div className="container mx-auto md:px-24">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline">
            <span className="mt-1 mr-3 text-gray-300">
              <RiGalleryFill />
            </span>
            Gallery
          </h2>
        </div>
      </div>

      <div className=" bg-zinc-900">
        <div className="container mx-auto md:px-28 pt-5">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline underline decoration-red-700">
            Acrylic on Canvas
          </h2>
        </div>
        <Galleryimg passData={ImgData} />
      </div>

      <div className=" bg-zinc-900">
        <div className="container mx-auto md:px-28 pt-5">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline underline decoration-red-700">
            Watercolor
          </h2>
        </div>
        <Galleryimg passData={Watercolor} />
      </div>
    </Fragment>
  );
};

export default Gallery;
