import { Fragment } from "react";



const Hero = (props) =>{
    return(
        <Fragment>
                <div className="min-h-screen overflow-hidden bg-zinc-900 relative -z-30">
                <div className="font-bold absolute text-center text-5xl w-full h-full flex items-center justify-center">
                      <span className="text-gray-200" Style="text-shadow: 0 0 5px #EF4444">Welcome to art Gallery </span>
                    </div>
                    <img className=" md:w-full md:object-cover object-cover h-screen" alt="l1" src={props.img} />
                </div>
        </Fragment>
    )
}

export default Hero;