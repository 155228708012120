
import { Route , Routes } from "react-router-dom";
import Index from "./Pages/Index";
import Gallery from "./Pages/Gallery";
import { Fragment } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Aboutus from "./Pages/Aboutus";
import Contact from "./Pages/Contact";
import NewArrival from "./Pages/NewArrival";
import Sculpture from "./Pages/Sculpture";
function App() {
  return (
      <Fragment>
        <Navbar/>
    <main>
      <Routes>
      <Route path="/" element={<Index />}/>
      <Route path="/newarrival" exact element={<NewArrival />}/>
      <Route path="/Gallery" exact element={<Gallery />}/>
      <Route path="/aboutus" exact element={<Aboutus />}/>
      <Route path="/contact" exact element={<Contact />}/>
      <Route path="/sculpture" exact element={<Sculpture />}/>
      </Routes>
      </main>
      <footer>
        <Footer/>
      </footer>
      </Fragment>
  );
}

export default App;
