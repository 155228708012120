import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'


const Model = (props) =>{
    const [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false)
      }
    
      function openModal() {
        setIsOpen(true)
      }
    return( <Fragment>
        <div className="block mb-12">
        <button
          type="button"
          className='h-full w-full'
          onClick={openModal}  >
            <div className='flex items-center h-full w-full'>
              <div className=' items-center w-full'>
          {/* <div className='bg-no-repeat bg-cover bg-center h-48 w-100 border-2 border-gray-500 rounded-md' style={{ backgroundImage: `url(${props.img})` }}></div> */}
          <img src={props.img} alt="img" className="md:h-24 mx-auto border-2 border-gray-500 rounded-md" />
          <div className='py-1  text-xs flex justify-between '><span className='text-gray-300 text-left mr-2'>{props.painting}<br/>{props.size}</span> <span className='text-red-500 font-bold'>{props.sold}</span></div>
        
        </div>
        
        </div>
        </button>
      </div>

      <Transition  show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="flex items-center justify-center min-h-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block border-2 border-red-200 overflow-hidden text-left md:align-middle transition-all transform bg-zinc-900 shadow-xl rounded-md">
              <Dialog.Title>
                        <div className='bg-zinc-900 px-2 text-sm border-b-red-500 border-b'>
                            <div className='flex justify-between'>
                                <div className='text-gray-200'></div>
                                <button type="button"
                    className="inline-flex justify-center font-medium text-xl text-gray-300  "
                    onClick={closeModal}
                  >
                     x
                  </button>
                            </div>
                        </div>
                </Dialog.Title>
              <img src={props.img} alt="img" className="w-full md:max-w-4xl max-h-96  " />
                <Dialog.Title>
                        <div className='bg-zinc-900 p-2 text-sm border-t-red-500 border-t'>
                            <div className='flex justify-between'>
                                <div className='text-gray-200'>{props.size}</div>
                                <button type="button"
                    className="inline-flex justify-center font-medium text-gray-300  "
                    onClick={closeModal}
                  >
                     Close
                  </button>
                            </div>
                        </div>
                </Dialog.Title>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Fragment> );
}


export default Model;