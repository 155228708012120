import { Fragment, useState } from "react";
import { FaAddressCard } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("Ve0P4xMK8Up2sdNEn");

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  // const sendEmail=()=> {
  // emailjs.sendForm('service_7op4ed9', 'template_jfg9xxs', form.current, 'Ve0P4xMK8Up2sdNEn')
  //     .then((result) => {
  //       alert("Message Sent, We will get back to you shortly", result.text);
  //     }, (error) => {

  //     });
  // }
  const submit = () => {
    if (name && email && message) {
      const serviceId = "service_7op4ed9";
      const templateId = "template_jfg9xxs";
      const userId = "Ve0P4xMK8Up2sdNEn";
      const templateParams = {
        name,
        email,
        mobile,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) =>
          alert("Message Sent, We will get back to you shortly")
        )
        .then((error) => console.log(error));

      setName("");
      setEmail("");
      setMobile("");
      setMessage("");
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <Fragment>
      <div className="py-8 bg-gradient-to-r from-red-900 to-amber-800 pt-36 md:pt-24">
        <div className="container mx-auto md:px-28">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline">
            <span className="mt-1 mr-3">
              <FaAddressCard />
            </span>{" "}
            Contact
          </h2>
        </div>
      </div>
      <div className="py-8 bg-zinc-900">
        <div className="container mx-auto md:px-28">
          <div className="grid grid-cols-4 gap-4 mb-4">
            {/* coloum one */}
            <div className="md:col-span-2 px-2 col-span-4 ">
              <div className="text-3xl text-center font-medium text-gray-300 underline mb-8 decoration-red-700">
                Contact Form
              </div>

              <div className="w-full rounded-md bg-gray-50 px-4 ring-2 ring-gray-200">
                <input
                  required
                  type="text"
                  placeholder="full names"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>

              <div className="w-full rounded-md bg-gray-50 px-4 ring-2 mt-4 ring-gray-200">
                <input
                  required
                  type="Email"
                  placeholder="E-mail Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>

              <div className="w-full rounded-md bg-gray-50 px-4 ring-2 mt-4 ring-gray-200">
                <input
                  required
                  placeholder="Number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>

              <div className="w-full rounded-md bg-gray-50 px-4 ring-2 mt-4 ring-gray-200">
                <input
                  required
                  type="address"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="my-3 w-full border-none bg-transparent outline-none focus:outline-none"
                />
              </div>
              <div className="w-full rounded-md mt-4 ">
                <button
                  onClick={submit}
                  type="submit"
                  className="w-full bg-red-800 text-gray-300 px-4 py-2 rounded-md"
                >
                  Submit
                </button>
              </div>
            </div>

            {/* coloum two */}

            <div className="md:col-span-2 col-span-4">
              <div className="text-3xl text-center font-medium text-gray-300 underline mb-8 decoration-red-700">
                Contact
              </div>
              <div className="flex mb-8 justify-center items-center">
                <p className="text-xs text-gray-300">
                  <p className="mb-3">
                    <span className="font-bold mr-4">E-mail</span>
                    <br />
                    sureshgosavi014@gmail.com<br />
                    saranggosavi24@gmail.com 
                  </p>
                  <p className="mb-3">
                    <span className="font-bold">Mobile</span> <br />
                    +91 9096 615 282
                    <br />
                  </p>
                  <p>
                    <span className="font-bold">Studio</span>
                    <br /> "Viola" G-102, Near Cipla Foundation,
                    <br />
                    Mumbai-Banglore Highway, Warje,
                    <br />
                    Pune : 411052
                  </p>
                </p>
              </div>

              <div className="text-3xl text-center font-medium text-gray-300 underline mb-8 decoration-red-700">
                Follow us
              </div>
              <div className="flex justify-center items-center">
                <a
                  href="https://www.facebook.com/suresh.gosavi.10"
                  rel="noreferrer"
                  target="_blank"
                  className="text-4xl  mx-2 text-indigo-500"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.instagram.com/sureshgosavi.24"
                  rel="noreferrer"
                  target="_blank"
                  className="text-4xl  mx-2 text-pink-500  "
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://pin.it/3mswTqaL9"
                  rel="noreferrer"
                  target="_blank"
                  className="text-4xl  mx-2 text-red-500  "
                >
                  <FaPinterest />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
