import { Fragment } from "react";
import { IoMdPerson } from 'react-icons/io';
import ME from "../assets/Suresh-Gosavi.jpg";
import Galleryimg from "../components/Index/Galleryimg";
import ar1 from "../assets/artical/ar-1.jpg";
import ar2 from "../assets/artical/ar-2.jpg";
import ar3 from "../assets/artical/ar-3.jpg";
import ar4 from "../assets/artical/ar-4.jpg";
import ar5 from "../assets/artical/ar-5.jpg";
import ar6 from "../assets/artical/ar-6.jpeg";
import ar7 from "../assets/artical/ar-7.jpeg";
import ar8 from "../assets/artical/ar-8.jpeg";
import ar9 from "../assets/artical/ar-9.jpeg";
import ar10 from "../assets/artical/ar-10.jpg";
import ar11 from "../assets/artical/ar-11.jpg";
import ar12 from "../assets/artical/ar-12.jpeg";

import m1 from "../assets/artical/m-1.png";
import m2 from "../assets/artical/m-2.png";
import m3 from "../assets/artical/m-3.png";
import m4 from "../assets/artical/m-4.png";
import m5 from "../assets/artical/m-5.png";
import m6 from "../assets/artical/m-6.png";
const Aboutus = () => {
  const ImgData = [
    {
      img: ar1,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar2,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar3,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar4,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar5,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar6,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar7,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar8,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar9,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar10,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar11,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: ar12,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },

    {
      img: m1,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: m2,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: m3,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: m4,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: m5,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
    {
      img: m6,
      title: "image title 2",
      painting: "",
      size: ``,
      sold: "",
    },
  ]

  return (
    <Fragment>
      <div className="py-8 bg-gradient-to-r from-red-900 to-amber-800 pt-36 md:pt-24">
        <div className="container mx-auto md:px-28">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline"><span className="mt-1 mr-3"><IoMdPerson/></span> About</h2>
        </div>
      </div>
      <div className="py-8 bg-zinc-900">
        <div className="container mx-auto md:px-28">
          <div className="grid grid-cols-6 gap-4">
            
            <div className="md:col-span-4 col-span-6 px-4 md:px-0">
              <div className="w-full">
                <img src={ME} alt="suresh gosavi" className="md:h-48 md:w-48 h-32 w-32 rounded-xl border-2 ml-4 mb-4 border-red-600 float-right" />  
                 <article className="text-xs leading-5 text-gray-300 pb-8">
                    <p className="mb-4">
                Suresh gosavi is the most sensitive and creative artist who spent his childhood in a small village.
The magic mantra of his self ecstasy and innovative paintings is his osession of novelty and deep study of art.
<br/></p>

<p className="mb-4">To find out very minute emotional inner side of human mind is too difficult.<br/>
The sensitive artist always try to exhibit the things in his art inspired from seen unseen outcome of good or bad moments which happened or experienced in his life.<br/>
Subject got the accompany of innovative thought and bring forth the amazing series of pictures. Devotion and strength.</p>


<p className="mb-4">Human does not get peace of mind after getting lots of amenities in life.<br/>
Not every one able to live the art of life.<br/>
Mental stability is much important to understood mind's legendary strength.<br/>
Like this we get many teaching thoughts through the tradition of our Indian cultural.<br/>
Faith has precious place in our life. faith get the company of Devotion, Knowledge, Thoughts and...</p>

<p className="mb-4">The one who achieve mind's peace who en grossly worship the strength of nature's auspicious, holy, magnificent, eternal seen-unseen things. He feels eternal strength; that new strength inspire him to live the life which gives him superb experience of happiness.
And we get same experience when We see Suresh gosavi's paintings.</p>

<p className="mb-4">His paintings have immeasurable distinctive featurel like simplicity of subject, prominent arrangement, emblematical use of symbols in indian culture and tradition, fine and suggestive colour combination, textures, calmness, cocentration and innocence of characters in paintings which activate same mood in viewer's mind it get experience the introvert thought.</p>
                </article>
                </div>
                <Galleryimg passData={ImgData} />
            </div>


            <div className="md:col-span-2 col-span-6 px-4 md:px-0">
              <div className=" w-full md:text-center ">
                <p className="text-xs  leading-5 text-gray-300 pb-8">
                  Born in 24th April,
                  <br />
                  Lives and works in Pune, Maharashtra (India)
                  <br />
                  A.T.D, G.D. Art,
                  <br />
                  Abhinav Kala Mahavidyalaya, Pune. 1996
                </p>
                <div>
                <p className="text-gray-400 font-bold pb-4">Awards</p>
<p className="text-xs leading-5 text-gray-300 pb-8">1994 Nashik Kala Niketan - Landscape Award<br/>
1993 Nashik Kala Niketan - Landscape Award<br/>
1993 V.V. Oak Smruti Award, Pune<br/>
1993 Maharashtra State Art Exhibition Award<br/>
1992 Nashik Kala Niketan - Landscape Award<br/>
1992 V.V. Oak Smruti Award, Pune
</p>
<p className="text-xs leading-5 text-gray-300 pb-8">
<span className="font-bold">Solo Exhibitions</span><br/>
2019 Jehangir Art Gallery, Mumbai<br/>
2017 Nehru Center Art Gallery (AC), Mumbai<br/>
2015 Jehangir Art Gallery, Mumbai<br/>
2013 Nehru Center Art Gallery, Mumbai<br/>
2009 The Oberoi Art Walk, Gallery, Mumbai<br/>
2008 Nehru Centre Art Gallery, Mumbai<br/>
2006 Art Plaza, Art Gallery, Mumbai<br/>
2005 Rudraksha Art Gallery, Pune<br/>
2004 Gallery Hussain, Pune<br/>
1995 Balgandhrva Art Gallery, Pune
</p>
<p className="text-xs leading-5 text-gray-300 pb-8">
<span className="font-bold">Group Exhibitions</span><br/>
2024 Jehangir Art Gallery, Mumbai<br/>
2024 Cymroza Art Gallery, Mumbai<br/>
2017 Artist Centre Art Gallery , Mumbai<br/>
2017 Cymroza Art Gallery, Mumbai<br/>
2016 Balgandhrva Art Gallery, Pune<br/>
2015 Lalit kala Academy, New Delhi<br/>
2011 Art 2 day Art Gallery, Pune<br/>
2011 Museum Art Gallery, Mumbai<br/>
2011 New Art Gallery, Pune<br/>
2010 Museum Art Gallery, Mumbai<br/>
2009 Kaladarpan Art Gallery, Pune<br/>
2008 India Art Gallery, Pune<br/>
2007 Jehangir Art Gallery, Mumbai<br/>
2006 Nehru Centre Art Gallery, Mumbai<br/>
2005 Balgandharva Art Gallery, Pune<br/>
2005 Rudraksha Art Gallery, Pune<br/>
2003 Balgandharva Art Gallery, Pune<br/>
1998 The 'Leela' Art Gallery, Mumbai<br/>
1996 Khushbu Art Gallery, Pune
</p>
<p className="text-xs leading-5 text-gray-300 pb-8">
<span className="font-bold">Collection</span><br/>
Several Collections are in India & Abroad</p> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Aboutus;
