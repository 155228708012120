import Galleryimg from "../components/Index/Galleryimg";
import { Fragment } from "react";
import { RiGalleryFill } from "react-icons/ri";
import IMG1 from "../assets/sculpture/Sculpture-1.jpeg";
import IMG2 from "../assets/sculpture/Sculpture-2.jpeg";
import IMG3 from "../assets/sculpture/Sculpture-3.jpeg";
import IMG4 from "../assets/sculpture/Sculpture-4.jpeg";
import IMG5 from "../assets/sculpture/Sculpture-5.jpeg";
import IMG6 from "../assets/sculpture/Sculpture-6.jpeg";
import IMG7 from "../assets/sculpture/Sculpture-7.jpeg";


const NewArrival = () => {
  const ImgData = [
    {
      img: IMG1,
      title: "painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },
    {
      img: IMG2,
      title: "  painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },
    {
      img: IMG3,
      title: "  painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },
    {
      img: IMG4,
      title: "  painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },
    {
      img: IMG5,
      title: "  painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },
    {
      img: IMG6,
      title: "  painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },
    {
      img: IMG7,
      title: "  painting",
      painting: "Sculpture - Fiberglass",
      size: `36x24x18`,
      sold: "SOLD",
    },

 
 ];
  return (
    <Fragment>
      <div className="py-8 bg-gradient-to-r from-red-900 to-amber-800 pt-36 md:pt-24">
        <div className="container mx-auto md:px-28">
          <h2 className="text-2xl font-bold text-gray-300 px-4 md:px-0 flex display-inline">
            <span className="mt-1 mr-3 text-gray-300">
              <RiGalleryFill />
            </span>{" "}
            Sculpture
          </h2>
        </div>
      </div>

      <Galleryimg passData={ImgData} />

      
    </Fragment>
  );
};

export default NewArrival;
