import { Fragment } from "react";
import { BiPhoneCall } from "react-icons/bi";
import { Link } from "react-router-dom";
function Navbar() {
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <nav className=" border-gray-200 px-2 sm:px-4 py-4 bg-zinc-900 border-b-red-500 border-b fixed top-0 w-full">
        <div className="container md:px-28 flex flex-wrap justify-between items-center mx-auto">
          
          <div className="flex justify-between flex-wrap w-full md:w-auto ">
          <Link to="/" className="flex">
            <span className="text-gray-200 hover:text-gray-400 text-xl">
              Suresh-Gosavi
            </span>
          </Link>
          <div className="md:hidden">
            <span className="text-white text-xl"><a href="tel:+91 90966 15282"><BiPhoneCall/></a></span>
          </div>
          </div>
         
           {/*<button
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button> */}
          {/* <div
            className={
              "w-full md:block md:w-auto" + (isOpen ? " flex" : " hidden")
            }
            id="mobile-menu"
          > */}
          <div className="w-full block md:w-auto">
            <ul className="flex mt-3 overflow-y-auto whitespace-no-wrap scroll-hidden md:flex-row md:space-x-8 md:mt-0 md:text-sm text-xs md:font-medium">
              <li>
                <Link
                  to="/"
                  className="block text-center py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 hover:text-amber-500"
                  // onClick={() => setIsOpen(false)}
                   
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to="/aboutus"
                  className="block text-center py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 hover:text-amber-500"
                  // onClick={() => setIsOpen(false)}
                  
                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to="/gallery"
                  className="block text-center py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 hover:text-amber-500"
                  // onClick={() => setIsOpen(false)}
                  
                >
                  Gallery 
                </Link>
              </li>
          

              <li>
                <Link
                  to="/newarrival"
                  className="block leading-5 text-center py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 w-full hover:text-amber-500"
                  // onClick={() => setIsOpen(false)}
                  
                >
                  New Arrival
                </Link>
              </li>

              <li>
                <Link
                  to="/sculpture"
                  className="block leading-5 text-center py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 w-full hover:text-amber-500"
                  // onClick={() => setIsOpen(false)}
                  
                >
                  Sculpture
                </Link>
              </li>


             

              

              <li>
                <Link
                  to="/Contact"
                  className="block text-center py-2 pr-4 pl-3 text-white rounded md:bg-transparent md:p-0 hover:text-amber-500"
                  // onClick={() => setIsOpen(false)}
                  
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}

export default Navbar;
