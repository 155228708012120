import { Fragment } from "react";
import Model from "./Model";

const Galleryimg = (props) => {
  const finalData = props.passData.map((fetchData) => (
    <div className="lg:col-span-3 md:col-span-3 col-span-1 w-full flex h-full">
      <Model img={fetchData.img} size={fetchData.size} painting={fetchData.painting} sold={fetchData.sold} />
    </div>
  ));

  return (
    <Fragment>
      <div className=" bg-zinc-900">
        <div className="container px-4 mx-auto md:px-28 py-12">
          <div className="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 gap-4 h-full">
            {finalData}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Galleryimg;
